import React, { lazy, Suspense, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";
// import { DbContextProvider } from "./DbContext";
const Form = lazy(() => import("./Form"));
const Home = lazy(() => import("./Home"));
const Data = lazy(() => import("./Data"));
const Edit = lazy(() => import("./Edit"));
const Export = lazy(() => import("./Export"));

const App = () => {
  const [screenWidth, setScreenWidth] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
	// initializing isAuthorized to true until auth code is complete
	const [isAuthorized, setIsAuthorized] = useState(true);

	const params = new URLSearchParams(window.location.search);
	const code = params.get("code");

	useEffect(() => {
		if (code === "wagner" || code === "demo") {
			setIsAuthorized(true);
		}
	}, [code]);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    setIsMounted(true);

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isSpanish, setIsSpanish] = useState(navigator.language === "es");

  const toggleSwitch = () => {
    setIsSpanish(!isSpanish);
  };

  if (!isMounted) {
    return null;
  }

  if (screenWidth < 933) {
    return (
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-5xl font-bold text-center mt-12">Seed Buddy</h1>
        <p className="text-center text-3xl mt-6 mb-4 ">
          {isSpanish ? "Su herramienta para gestionar datos de semillas" : "Your Tool For Managing Seed Data"}
        </p>
        <div className="text-7xl">🧘</div>
        <p className="text-center text-2xl mt-8 mb-4 mx-8">
					{isSpanish ? "Seed Buddy necesita espacio para respirar. Por favor, use una computadora portátil/escritorio o una tableta posicionada horizontalmente." :
          "Seed Buddy needs room to breathe. Please use a laptop/desktop or horizontally-positioned tablet."}
        </p>
      </div>
    );
  }

	if (!isAuthorized) {
		return (
			<div className="flex flex-col items-center justify-center">
				<h1 className="text-5xl font-bold text-center mt-12">Seed Buddy</h1>
				<p className="text-center text-3xl mt-6 mb-4 ">
					{isSpanish ? "Su herramienta para gestionar datos de semillas" : "Your Tool For Managing Seed Data"}
				</p>
				<img
					src="/logo.png"
					alt="Jill Wagner logo"
					width="500"
					style={{ display: "block", margin: "auto" }}
				/>
				<p className="text-center text-3xl mt-8 mb-4 mx-8">
					{isSpanish ? "No está autorizado para ver esta página." :
					"You are not authorized to view this page."}
				</p>
				<p className="text-center text-2xl mt-8 mx-8">
					{isSpanish ? "¿Interesado en usar Seed Buddy?" : "Interested in using Seed Buddy?"}
				</p>
				<p className="text-center text-2xl mx-8">
					{isSpanish ? "Contacte a" : "Contact"}{" "}
					<a href="mailto:jillwagner3@icloud.com" className="text-blue-500">Jill Wagner</a>
				</p>
			</div>
		);
	}

  return (
    <Router>
      <Suspense fallback={<div>{isSpanish ? "Cargando..." : "Loading"}</div>}>
        <nav className="w-full  px-8 py-4 text-xl bg-[#84BF86]">
          <ul className="flex flex-row justify-between gap-12 font-bold">
            <li className="hover:scale-125">
              <NavLink to="/">
                {" "}
                <img
                  src="/logo.png"
                  alt="Jill Wagner logo"
                  width="50"
                  style={{ display: "block", margin: "auto" }}
                />
              </NavLink>
            </li>
            <div className="flex gap-12">
              <li className="hover:scale-110">
                <NavLink to="/form">{isSpanish ? "Forma" : "Form"}</NavLink>
              </li>
              <li className="hover:scale-110">
                <NavLink to="/data">{isSpanish ? "Datos" : "Data"}</NavLink>
              </li>
              <li className="hover:scale-110">
                <NavLink to="/export">{isSpanish ? "Exportar" : "Export"}</NavLink>
              </li>
            </div>
          </ul>
        </nav>
				<div className="w-full flex items-center justify-end">
        <div className="flex flex-col items-center mr-12">
          <button
            onClick={toggleSwitch}
            className={`relative inline-flex items-center rounded-full w-12 h-6 mt-6 pl-1 transition-colors ${
              isSpanish ? "bg-[#732c70]" : "bg-[#84BF86]"
            }`}
          >
            <span
              className={`inline-block w-4 h-4 transform transition-transform rounded-full ${
                isSpanish
                  ? "translate-x-6 bg-[#84BF86]"
                  : "translate-x-0 bg-[#732c70]"
              }`}
            />
          </button>
          <span className="text-[#732c70] mt-2">English/Español</span>
        </div>
      </div>
        <Routes>
          <Route path="/" element={<Home isSpanish={isSpanish} isAuthorized={isAuthorized}/>} />
          <Route path="/form" element={<Form isSpanish={isSpanish}  isAuthorized={isAuthorized}/>} />
          <Route path="/data" element={<Data isSpanish={isSpanish}/>} />
          <Route path="/edit" element={<Edit isSpanish={isSpanish} />} />
          <Route path="/export" element={<Export isSpanish={isSpanish} />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
